// app/javascript/controllers/checkout_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["totalAmount", "depositInfo"]

  connect() {
    this.updateSummary()
  }

  updateSummary(event) {
    console.log("updateSummary called")

    const isPercentDeposit = event ? event.target.value === "deposit" : false
    const isFixedDeposit = event ? event.target.value === "fixed_deposit" : false
    const fullAmount = parseFloat(this.totalAmountTarget.dataset.fullAmount)
    const depositPercent = parseFloat(this.totalAmountTarget.dataset.depositPercent)
    const depositAmount = parseFloat(this.totalAmountTarget.dataset.depositFixed)
    const monthPrior = this.totalAmountTarget.dataset.monthPrior
    const monthText = this.totalAmountTarget.dataset.monthText

    if (isPercentDeposit) {
      const depositAmount = fullAmount * (depositPercent / 100)
      const remainingAmount = fullAmount - depositAmount

      this.totalAmountTarget.textContent = `$${depositAmount.toFixed(2)}`
      this.depositInfoTarget.textContent = `${depositPercent}% deposit paid now. Remaining balance of $${remainingAmount.toFixed(2)} due ${monthPrior} ${monthText} prior to camp start.`
      this.depositInfoTarget.classList.remove("hidden")
    } else if (isFixedDeposit){
      const remainingAmount = fullAmount - depositAmount

      this.totalAmountTarget.textContent = `$${depositAmount.toFixed(2)}`
      this.depositInfoTarget.textContent = `$${depositAmount.toFixed(2)} deposit paid now. Remaining balance of $${remainingAmount.toFixed(2)} due ${monthPrior} ${monthText} prior to camp start.`
      this.depositInfoTarget.classList.remove("hidden")
    } else {
      this.totalAmountTarget.textContent = `$${fullAmount.toFixed(2)}`
      this.depositInfoTarget.classList.add("hidden")
    }
  }
}
