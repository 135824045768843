import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { files: Array }

  downloadAll() {
    this.filesValue.forEach((file, index) => {
      setTimeout(() => {
        const a = document.createElement("a");
        a.href = file.url;
        a.download = file.filename || `file-${index + 1}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }, index * 500);
    });
  }
}
